import React from 'react';

const NotFoundPage = () => {
  return (
    <article>
      <h1>Page not found.</h1>
    </article>
  );
};

export { NotFoundPage};
