import { calendarConstants } from '../constants';

const initialState = {
  resources: [],
  calendars: [],
  events: [],
  gettingSchedule: false,
  gettingResources: false,
};

export function calendar(state = initialState, action) {
  switch (action.type) {
    case calendarConstants.RESOURCES_REQUEST:
      return {
        ...state,
        gettingResources: true,
      };
    case calendarConstants.RESOURCES_SUCCESS:
      return {
        ...state,
        resources: action.resources,
        events: action.events,
        gettingResources: false,
      };
    case calendarConstants.RESOURCES_FAILURE:
      return {
        ...state,
        gettingResources: false,
      };
    case calendarConstants.CALENDARS_REQUEST:
      return {
        ...state,
        gettingCalendars: true,
      };
    case calendarConstants.CALENDARS_SUCCESS:
      return {
        ...state,
        calendars: action.calendars,
        gettingCalendars: false,
      };
    case calendarConstants.CALENDARS_FAILURE:
      return {
        ...state,
        gettingCalendars: false,
      };
    default:
      return state
  }
}