import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => {
  return {
    root: {
      background: props => props.color === 'primary' ? 'linear-gradient(292deg, #00baff, #007aff)': null,
      color: 'white',
      borderRadius: 100,
      boxShadow: 'none',
      '&:active': {
        boxShadow: 'none',
      },
    },
  };
});

const StyledButton = props => {
  const { children, classes, ...otherProps } = props;
  const classesNew = useStyles(props);

  return (
    <React.Fragment>
      <Button classes={{ root: classesNew.root, ...classes }} {...otherProps}>
        {children}
      </Button>
    </React.Fragment>
  );
};

Button.propTypes = {
  classes: PropTypes.object,
};

export default StyledButton;
