import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    // position: 'absolute',
    // bottom: 0,
  },
  copyright: {
    color: '#6b6b6b',
  }
}));


export const Copyright = () => {
  const classes = useStyles();
  return (<Typography variant="caption" display="block" gutterBottom className={classes.root}><span className={classes.copyright}>© 2014-{new Date().getFullYear()} Kuna. All rights reserved.</span></Typography>);
};
