import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      outline: 'none',
    },
  };
});

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  };
}

export default props => {
  const { children, paperClass, ...otherProps } = props;
  const classes = useStyles(props);
  return (
    <Modal {...otherProps}>
      <div style={getModalStyle()} className={`${classes.paper} ${paperClass}`}>
        {children}
      </div>
    </Modal>
  );
};
