import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Modal from '../../../components/base/Modal';
import Button from '../../../components/base/Button';
import TextField from '../../../components/base/TextField';

const useStyles = makeStyles(theme => ({

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1 / 3),
    top: theme.spacing(1 / 3),
    color: theme.palette.grey[500],
  },
  buttonCancel: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  inputRoot: {
    backgroundColor: '#F5F5F5',
    borderRadius: 3,
  },
}));


const GroupNameModal = ({ open, onSave, onClose }) => {
  const classes = useStyles();
  const [name, setName] = useState('');

  const submit = e => {
    e.preventDefault();
    onSave(name);
  };

  return (
    <Modal open={open}>
      <Box width={400}>
        <form onSubmit={submit}>
          <Box display="flex" flexDirection="column">
            <Box mt={1}>
              <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                margin="none"
                hiddenLabel
                variant="filled"
                placeholder="Group name"
                InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                fullWidth
                required
              />
            </Box>
          </Box>
          <Box display="flex" mt={4}>
            <div className={classes.buttonCancel}>
              <Button color="primary" size="small" onClick={onClose}>Cancel</Button>
            </div>
            <Button variant="contained" color="primary" size="small" type="submit">Save</Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

GroupNameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { GroupNameModal };