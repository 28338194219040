export const calendarConstants = {
  RESOURCES_REQUEST: 'USERS_RESOURCES_REQUEST',
  RESOURCES_SUCCESS: 'USERS_RESOURCES_SUCCESS',
  RESOURCES_FAILURE: 'USERS_RESOURCES_FAILURE',
  CALENDARS_REQUEST: 'USERS_CALENDARS_REQUEST',
  CALENDARS_SUCCESS: 'USERS_CALENDARS_SUCCESS',
  CALENDARS_FAILURE: 'USERS_CALENDARS_FAILURE',
  repeat: {
    NO: 0,
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    ANNUALLY: 4,
  },
  settings: {
    ENABLED: 0,
    RECORDING: 1,
    NOTIFICATION: 2,
    SOUND: 3,
    LIGHT: 4,
  },
  SLOTS_COUNT: 96,
};
