import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { users } from './users.reducer';
import { notifier } from './notifier.reducer';
import { calendar } from './calendar.reducer';

const rootReducer = combineReducers({
  auth,
  users,
  notifier,
  calendar,
});

export default rootReducer;