function getApiURL() {
  if (process.env.REACT_APP_API_URL) return process.env.REACT_APP_API_URL;

  const hostname = window.location.hostname;

  if (hostname === 'commercial.bowserdance.kunadev.com') { // dev
    return 'https://bowserdance.kunadev.com';
  } else if (hostname === 'commercial.toadettedance.kunadev.com') { // stage
    return 'https://toadettedance.kunadev.com';
  } else if (hostname === 'commercial.kunadev.com') { // prod
    return 'https://luigidance.kunadev.com';
  }
}

export const BASE_URL = getApiURL();

export const API_URL = `${BASE_URL}/api/v1`;
export const ACCOUNT_URL = `${BASE_URL}/account/profile/`;
export const LOGOUT_URL = `${BASE_URL}/account/logout/`;
