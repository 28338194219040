import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Scheduler, { DATE_FORMAT, SchedulerData, ViewTypes } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import moment from 'moment';
import delve from 'dlv';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Radio from '@material-ui/core/Radio';

import withDragDropContext from './components/withDnDContext'
import { calendarActions } from '../../actions/calendar.actions';
import cameraIcon from './icons/camera.svg';
import groupCollapsedIcon from './icons/group-collapsed.svg';
import groupExtendedIcon from './icons/group-expanded.svg';
import groupIcon from './icons/group.svg';
import ungroupIcon from './icons/ungroup.svg';
import schedulesIcon from './icons/schedules.svg';
import schedulesWhiteIcon from './icons/schedules-white.svg';
import moveIcon from './icons/move.svg';
import closeIcon from './icons/close.svg';
import clearIcon from './icons/clear.svg';
import { SchedulesEditModal } from './components/SchedulesEditModal';
import { ScheduleEditModal } from './components/ScheduleEditModal';
import { GroupNameModal } from './components/GroupNameModal';
import Checkbox from '../../components/base/Checkbox';
import Button from '../../components/base/Button';
import './scheduler-style.css';
import styles from './styles';
import { calendarService } from '../../services';
import { timeConvert } from '../../helpers';


class SchedulePage extends React.Component {
  constructor(props) {
    super(props);

    this.schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Week,
      false,
      false,
      {
        minuteStep: 15,
        eventItemHeight: 32,
        eventItemLineHeight: 65,
        tableHeaderHeight: 92,
        resourceName: this.renderMainHeader(),
        nonWorkingTimeBodyBgColor: '#efefef',
        nonWorkingTimeHeadBgColor: '#efefef',
        dayResourceTableWidth: 330,
        weekResourceTableWidth: 330,
        monthResourceTableWidth: 330,
        views: [
          { viewName: 'Day', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
          { viewName: 'Week', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
          { viewName: 'Month', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
        ],
        editRenderData: this.editScheduleRenderData,
      }
    );
    this.schedulerData.localeMoment.locale('en');

    this.state = {
      menuAnchorEl: null,
      viewModel: this.schedulerData,
      schedulesOpened: false,
      selectedSchedule: {},
      scheduleEditOpened: false,
      organizeGroups: false,
      slotSelected: {},
      groupNameOpened: false,
      moveActive: false,
      slotMoveSelected: null,
    };
  }

  componentWillMount() {
    this.props.getResources();
    this.props.getCalendars();
  }

  mergeEvents = (headerItem, id, showPopover) => {

    if (headerItem.count === 0) {
      return headerItem;
    }

    let newEvent = {
      render: true,
      span: 1,
      combinedEvents: [],
      eventItem: {
        id: id,
        title: '',
        resizable: false,
        showPopover,
      },
    };

    const slotsCount = 96;
    let interval = Array(slotsCount).fill('0');
    let hasEvents = false;

    headerItem.events.forEach(event => {
      const settingIsOn = delve(event, 'eventItem.rule.settings.0.on', false);

      if (!settingIsOn) {
        return;
      }

      for (let i = 0; i < event.eventItem.rule.intervals.length; i++) {
        if (event.eventItem.rule.intervals.charAt(i) === '1') {
          interval[i] = '1';
        }
      }

      if (!newEvent.eventItem.start || newEvent.eventItem.start > event.eventItem.start) {
        newEvent.eventItem.start = event.eventItem.start;
      }

      if (!newEvent.eventItem.end || newEvent.eventItem.end < event.eventItem.end) {
        newEvent.eventItem.end = event.eventItem.end;
      }

      newEvent.combinedEvents.push(event);

      newEvent.eventItem.id = newEvent.eventItem.id + '_' + event.eventItem.id;
      newEvent.eventItem.title = newEvent.eventItem.title + ' ' + event.eventItem.title;
      newEvent.eventItem.resourceId = event.eventItem.resourceId;
      newEvent.eventItem.calendarId = event.eventItem.calendarId;
      hasEvents = true;
    });

    newEvent.eventItem.intervals = interval.join('');

    headerItem.events = hasEvents ? [newEvent] : [];
    headerItem.count = hasEvents ? 1 : 0;

    return headerItem;
  };

  editScheduleRenderData = (schedulerData, renderData) => {

    // merge intersect time intervals events into one to present them in one row per camera using gradient background
    // instead of multiple lines per each event as done in original react-big-schedule component

    renderData.forEach(data => {
      // keep same row height even if there are multiple events initially
      data.rowHeight = 65;
      data.headerItems.forEach((headerItem, id) => {
        this.mergeEvents(headerItem, id, schedulerData.viewType !== ViewTypes.Day);
      });
    });

    return renderData;
  };

  handleSelectedChange = name => event => {
    const target = event.target;
    this.setState(prevState => ({
      slotSelected: {
        ...prevState.slotSelected,
        [name]: target.checked
      }
    }), () => this.renderHeader());
  };
  renderHeader = () => {
    this.schedulerData.config.resourceName = this.renderMainHeader();
    this.setState({
      viewModel: this.schedulerData,
    })
  };

  onOrganize = () => {
    this.setState({ organizeGroups: true }, () => this.renderHeader());
  };

  onOrganizeCancel = () => {
    this.setState({ organizeGroups: false, slotSelected: {} }, () => this.renderHeader());
  };

  getResourceToChange = () => {
    const { slotSelected } = this.state;
    const data = {
      cameras: [],
      groups: []
    };
    Object.keys(slotSelected).forEach(slotId => {
      if (!slotSelected[slotId]) return;

      const key = slotId.charAt(0) === 'c' ? 'cameras' : 'groups';
      data[key].push(+slotId.substr(1));
    });

    return data;
  };

  group = () => {
    this.setState({ groupNameOpened: true })
  };

  move = () => {
    this.setState({ moveActive: true }, () => this.renderHeader())
  };

  onMoveSubmit = () => {
    const { slotMoveSelected } = this.state;
    const { moveResources } = this.props;

    moveResources({ ...this.getResourceToChange(), group: +slotMoveSelected.substr(1) });

    this.setState({
      moveActive: false,
      slotMoveSelected: null,
      slotSelected: {},
      organizeGroups: false
    }, () => this.renderHeader());
  };

  handleMoveSelectedChange = slotId => {
    this.setState({ slotMoveSelected: slotId }, () => this.renderHeader());
  };

  onMoveCancel = () => {
    this.setState({ moveActive: false, slotMoveSelected: null }, () => this.renderHeader());
  };

  unGroup = () => {
    const { unGroupResources } = this.props;

    unGroupResources(this.getResourceToChange());
  };

  renderMainHeader = () => {
    const { classes } = this.props;
    const { organizeGroups, slotSelected = {}, moveActive } = this.state || {};

    if (moveActive) {
      return (
        <Box className={`${classes.mainHeader} ${classes.mainHeaderMove}`}>
          <Box justifyContent="space-between" display="flex" width="100%">
            <Button size="small" aria-label="Cancel" onClick={this.onMoveCancel}
                    classes={{ label: classes.moveCancelLabel }}>Cancel</Button>
            <Button size="small" variant="contained" aria-label="Save" onClick={this.onMoveSubmit}
                    classes={{ root: classes.mainHeaderGroupButtonRoot, label: classes.mainHeaderGroupButtonLabel }}>
              <img alt="" src={moveIcon} className={classes.groupButtonIcon} />
              Save
            </Button>
          </Box>
          <Box fontSize={16}>
            Select destination:
          </Box>
        </Box>
      );
    }

    if (organizeGroups) {
      return (
        <Box className={`${classes.mainHeader} ${classes.mainHeaderBlue}`}>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button
              disabled={!Object.keys(slotSelected).find(slotId => slotId.charAt(0) === 'g' && slotSelected[slotId])}
              classes={{ root: classes.mainHeaderGroupButtonRoot, label: classes.mainHeaderGroupButtonLabel }}
              size="small"
              variant="contained" onClick={this.unGroup}>
              <img alt="" src={ungroupIcon} className={classes.groupButtonIcon} />
              Ungroup
            </Button>
            <Box color="white">
              <IconButton size="small" aria-label="Cancel" onClick={this.onOrganizeCancel}>
                <img alt="cancel" src={closeIcon} />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" width="100%" justifyContent="space-between" mt={1}>
            <Button
              disabled={!Object.keys(slotSelected).find(slotId => slotSelected[slotId])}
              classes={{ root: classes.mainHeaderGroupButtonRoot, label: classes.mainHeaderGroupButtonLabel }}
              size="small" variant="contained"
              onClick={this.group}>
              <img alt="" src={groupIcon} className={classes.groupButtonIcon} />
              Group
            </Button>
            <Button
              disabled={!Object.keys(slotSelected).find(slotId => slotSelected[slotId])}
              classes={{ root: classes.mainHeaderGroupButtonRoot, label: classes.mainHeaderGroupButtonLabel }}
              size="small" variant="contained"
              onClick={this.move}>
              <img alt="" src={moveIcon} className={classes.groupButtonIcon} />
              Move
            </Button>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box display="flex" flexDirection="column" className={classes.mainHeader}>
          <Box className={classes.mainHeaderTitle}>Cameras</Box>
          <Box className={classes.mainHeaderOrganize}
               onClick={this.onOrganize}>Organize</Box>
        </Box>
      );
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.events !== this.props.events) {
      this.schedulerData.setResources(nextProps.resources || []);
      this.schedulerData.setEvents(nextProps.events || []);
    }
  }

  render() {
    const { viewModel, schedulesOpened, selectedSchedule, scheduleEditOpened, groupNameOpened, menuAnchorEl, filterSchedule } = this.state;
    const { classes, calendars } = this.props;

    let leftCustomHeader = (
      <Button
        variant="contained" color="primary" size="small"
        classes={{ label: classes.showSchedulesButtonLabel }}
        onClick={this.openSchedulesEdit}>
        <img alt="" src={schedulesWhiteIcon} className={classes.scheduleIcon} />
        Show Schedules
      </Button>
    );

    const filteredCalendars = calendars.filter(calendar => filterSchedule ? calendar.name.toLowerCase().indexOf(filterSchedule.toLowerCase()) !== -1 : true);

    return (
      <Box>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          leftCustomHeader={leftCustomHeader}
          toggleExpandFunc={this.toggleExpandFunc}
          eventItemTemplateResolver={this.eventItemTemplateResolver}
          nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
          slotItemTemplateResolver={this.slotItemTemplateResolver}
          eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
        />
        <SchedulesEditModal open={schedulesOpened} onClose={this.closeSchedulesEdit} />
        <ScheduleEditModal scheduleData={selectedSchedule} open={scheduleEditOpened}
                           onClose={this.onScheduleEditClose} />
        <GroupNameModal open={groupNameOpened} onClose={this.onGroupNameClose} onSave={this.onGroupNameSave} />

        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={this.onMenuClose}
          transitionDuration={0}
        >
          <ListItem>
            <SearchIcon className={classes.menuIcon} />
            <InputBase onChange={this.onScheduleSearch} placeholder="Search Schedules...." />
          </ListItem>
          <Divider />

          <ListItem className={`MuiListItem-button ${classes.menuItem}`} onClick={() => this.onSelectSchedule(null)}>
            <img alt="" src={clearIcon} className={classes.menuIcon} />
            <div className={classes.deleteText}>Clear Schedule</div>
          </ListItem>

          <ListItem className={`MuiListItem-button ${classes.menuItem}`} onClick={this.onCreateSchedule}>
            <img alt="" src={schedulesIcon} className={classes.menuIcon} />
            <div>Create New Schedule...</div>
          </ListItem>
          {filteredCalendars.map((calendar, key) =>
            <ListItem className={`MuiListItem-button ${classes.menuItem}`} key={key}
                      onClick={() => this.onSelectSchedule(calendar.id)}>
              {calendar.name}
            </ListItem>)
          }
        </Menu>
      </Box>
    );
  }


  prevClick = (schedulerData) => {
    schedulerData.prev();
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData
    })
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData
    })
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.setEvents(this.props.events);
    this.setState({
      viewModel: schedulerData
    })
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);

    this.setState({
      viewModel: schedulerData
    })
  };

  eventClicked = (schedulerData, event) => {
    const { calendars } = this.props;

    this.onScheduleEditOpen(calendars.find(calendar => calendar.id === event.calendarId));
  };

  ops1 = (schedulerData, event) => {
    alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  ops2 = (schedulerData, event) => {
    alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (window.confirm(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)) {

      let newFreshId = 0;
      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId)
          newFreshId = item.id + 1;
      });

      let newEvent = {
        id: newFreshId,
        title: 'New event you just created',
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: 'purple'
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData
      })
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (window.confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData
    })
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (window.confirm(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData
    })
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (window.confirm(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData
      })
    }
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };

  eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {

    let backgroundColor = 'rgba(0,139,236,1)';
    let backgroundGrad = null;

    let divStyle = {
      border: 'none',
      backgroundColor: backgroundColor,
      height: mustBeHeight,
    };

    if (schedulerData.viewType !== ViewTypes.Day && event.intervals) {
      // Gradient to show Schedule blocks
      let intervals = event.intervals || '';
      backgroundGrad = 'linear-gradient(90deg';

      let scheduleBarPercent = 100 / intervals.length;
      let scheduleBar = intervals.match(/(\d)\1*/g) || '';
      let scheduleBarColor = 0;
      let scheduleBarPosition = 0;
      let scheduleBarLength = 0;
      for (let i = 0; i < scheduleBar.length; i++) {
        scheduleBarColor = scheduleBar[i][0];
        scheduleBarLength += scheduleBar[i].length * scheduleBarPercent;

        let currentColor = (scheduleBarColor === '0') ? 'rgba(0,122,255,0)' : 'rgba(0,122,255,1)';

        backgroundGrad += ', ' + currentColor + ' ' + scheduleBarPosition + '%, ' + currentColor + ' ' + scheduleBarLength + '%';
        scheduleBarPosition = scheduleBarLength;
      }

      backgroundGrad += ')';
      // Gradient end

      divStyle['background'] = backgroundGrad;
    }

    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return <div key={event.id} className={mustAddCssClass} style={divStyle}>
      <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px`, color: '#1A1A1E' }}>{''}</span>
    </div>;
  };

  nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {

    style.fontSize = 16;
    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {
          formattedDateItems.map((formattedItem, index) => {
            const [string, number] = formattedItem.split(' ');
            return (
              <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: '#DDDDDD' }}> {number}</div>
                <div style={{ color: '#AAAAAA' }}> {string}</div>
              </div>
            )
          })
        }
      </th>
    );
  };

  slotItemTemplateResolver = (schedulerData, slot, slotClickedFunc, width, clsName) => {
    const { classes } = this.props;
    const { slotSelected, organizeGroups, moveActive, slotMoveSelected } = this.state;

    const slotData = schedulerData.getSlotById(slot.slotId);

    const isCamera = slotData.type === 'camera';

    if (!slot.render) return null;

    return (
      <Box display="flex" flexDirection="column" overflow="auto">
        <Box display="flex">
          <Box display="flex" alignItems="center" className={classes.leftColumnTextWidth}>
            {organizeGroups && !moveActive && <Box>
              <Checkbox
                checked={slotSelected[slot.slotId] || false}
                onChange={this.handleSelectedChange(slot.slotId)}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
              />
            </Box>}
            {moveActive && !isCamera && <Box>
              <Radio
                checked={slotMoveSelected === slot.slotId}
                onChange={() => this.handleMoveSelectedChange(slot.slotId)}
                color="primary"
              />
            </Box>}

            <Box ml={2 * slot.indent + 1} mr={1}>
              {isCamera ? <img alt="" src={cameraIcon} /> :
                <img alt=""
                     src={slot.expanded ? groupExtendedIcon : groupCollapsedIcon}
                     className={classes.groupIcon}
                     onClick={() => this.toggleExpandFunc(schedulerData, slot.slotId)} />}
            </Box>
            <Box className={`${classes.resource} ${isCamera ? '' : classes.groupResource}`}>
              {slotData.name}
              {isCamera && <span className={classes.timezone}>{slotData.timezone}&nbsp;</span>}
              {isCamera && slotData.calendarName &&
              <span className={classes.cameraScheduleResource}>{slotData.calendarName}</span>}
            </Box>
          </Box>
          <Box ml="auto">
            <IconButton edge="end" style={{ color: "rgba(0, 120, 255, 0.6)" }}
                        onClick={this.toggleMenu(slot.slotId)}>
              <MoreIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {

    let mustBeHeight = 16;

    let intervals = eventItem.intervals;


    let scheduleBar = intervals.match(/(\d)\1*/g);
    let popoverBars = Array(scheduleBar.length);

    let scheduleBarPercent = 100 / intervals.length;
    for (let j = 0; j < popoverBars.length; j++) {
      let scheduleBarColor = 0;
      let scheduleBarPosition = 0;
      let scheduleBarLength = 0;
      let emptyBar = (scheduleBar[j][0] === '0');
      popoverBars[j] = (emptyBar) ? '' : 'linear-gradient(90deg';
      for (let i = 0; i < scheduleBar.length; i++) {
        scheduleBarColor = scheduleBar[i][0];

        scheduleBarLength += scheduleBar[i].length * scheduleBarPercent;

        let currentColor = (scheduleBarColor === '0') ? 'rgba(0,122,255,0)' : (j === i) ? 'rgba(0,122,255,1)' : 'rgba(221,221,221,1)';
        popoverBars[j] += (emptyBar) ? '' : ', ' + currentColor + ' ' + scheduleBarPosition + '%, ' + currentColor + ' ' + scheduleBarLength + '%';

        if (i === scheduleBar.length - 1) {
          popoverBars[j] += (emptyBar) ? '' : ')';
        }

        scheduleBarPosition = scheduleBarLength;

      }
    }

    popoverBars = popoverBars.filter(Boolean);

    const slots = calendarService.getTimeSlots(intervals);

    return (
      <div style={{ width: '240px' }}>
        {popoverBars.map((bar, id) => (
          <Box width="100%" mb={1} key={id}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <div style={{ width: '84px', height: mustBeHeight + 'px', background: bar }} />
              </Box>
              <Box><b>{timeConvert(slots[id].start_time)}</b> to <b>{timeConvert(slots[id].end_time)}</b></Box>
            </Box>
            <Divider />
          </Box>
        ))}
      </div>
    );
  };

  openSchedulesEdit = () => {
    this.setState({ schedulesOpened: true })
  };

  closeSchedulesEdit = () => {
    this.setState({ schedulesOpened: false, })
  };

  onScheduleEditOpen = (selectedSchedule = {}) => {
    this.setState({ selectedSchedule, scheduleEditOpened: true });
  };

  onScheduleEditClose = () => {
    this.setState({ selectedSchedule: {}, scheduleEditOpened: false });
  };

  onGroupNameClose = () => {
    this.setState({ groupNameOpened: false });
  };

  onGroupNameSave = (groupName) => {
    const { groupResources } = this.props;

    groupResources({ name: groupName, ...this.getResourceToChange() }).then(() => {
      this.setState({ groupNameOpened: false });
      this.onOrganizeCancel()
    });
  };

  toggleMenu = selectedSlotId => event => {
    this.setState({ menuAnchorEl: event.currentTarget, selectedSlotId })
  };

  onSelectSchedule = scheduleId => {
    const { selectedSlotId } = this.state;

    const type = selectedSlotId.charAt(0) === 'c' ? 'camera' : 'group';
    this.props.applySchedule(scheduleId, type, +selectedSlotId.substr(1));
    this.onMenuClose();
  };

  onCreateSchedule = () => {
    this.setState({ selectedSchedule: {}, scheduleEditOpened: true });
    this.onMenuClose();
  };

  onScheduleSearch = event => {
    this.setState({ filterSchedule: event.target.value })
  };

  onMenuClose = () => {
    this.setState({ menuAnchorEl: null, filterSchedule: '' })
  }
}

function mapState(state) {
  const { events, resources, calendars } = state.calendar;
  return { events, resources, calendars };
}

const actionCreators = {
  getResources: calendarActions.getResources,
  getCalendars: calendarActions.getCalendars,
  groupResources: calendarActions.groupResources,
  unGroupResources: calendarActions.unGroupResources,
  applySchedule: calendarActions.applySchedule,
  moveResources: calendarActions.moveResources,
};

const connectedSchedulePage = connect(mapState, actionCreators)(withStyles(styles)(withDragDropContext(SchedulePage)));
export { connectedSchedulePage as SchedulePage };
