import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, withStyles } from '@material-ui/core';

import Button from './base/Button';
import { ACCOUNT_URL } from '../constants';
// import { userActions } from '../actions';
import { userService } from '../services';


const MenuButton = withStyles({
  root: {
    textTransform: 'none',
    color: props => props.selected ? '#404040' : '#666666',
    fontSize: 18,
    fontWeight: 'normal',
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
})(Button);

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#FFF',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.05)',
    minHeight: 73,
  },
  logo: {
    width: 65,
    marginRight: 20,
  },
  grow: {
    flexGrow: 1,
  },
  menuButtonWrapper: {
    marginLeft: 12,
    marginRight: 12,
  },
}));

const TopBar = props => {
  const classes = useStyles();
  const { pathname } = props.location;
  const { auth: { loggedIn } } = props;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={4}>
        <Toolbar className={classes.toolbar}>
          <img src="/img/logo.png" alt="Logo" className={classes.logo} />
          <div className={classes.grow} />
          <div className={classes.menuButtonWrapper}>
            <MenuButton disableRipple onClick={() => window.location.href = ACCOUNT_URL}>Account</MenuButton>
          </div>
          <div className={classes.menuButtonWrapper}>
            <MenuButton disableRipple component={Link} to="/schedule" selected={pathname.startsWith('/schedule')}>Schedule</MenuButton>
          </div>
          <div className={classes.menuButtonWrapper}>
          {!loggedIn &&
            <MenuButton disableRipple component={Link} to="/login" selected={pathname.startsWith('/login')}>Login</MenuButton>}
            {/* using direct logout to redirect back to cloud application*/}
            {loggedIn && <MenuButton onClick={() => userService.logout()}>Log Out</MenuButton>}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

function mapState(state) {
  const { auth } = state;
  return { auth };
}

const actionCreators = {
  // logout: userActions.logout
};

const AppBarConnected = withRouter(connect(mapState, actionCreators)(TopBar));
export { AppBarConnected as MainAppBar };
export default TopBar;