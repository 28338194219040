import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress/index';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => {
  return {
    buttonProgress: {
      color: '#CC5337',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  };
});

const StyledButton = props => {
  const { loading, children, danger, ...otherProps } = props;
  const classesNew = useStyles();

  return (
    <React.Fragment>
      <Button disabled={loading} {...otherProps}>
        {children}
        {loading && <CircularProgress size={24} className={classesNew.buttonProgress} />}
      </Button>
    </React.Fragment>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object,
};

export default StyledButton;
