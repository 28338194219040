export default theme => ({
  resource: {
    fontSize: 16,
    color: '#1A1A1E',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: 65,
    textAlign: 'left',
    alignSelf: 'center',
  },
  groupResource: {
    fontWeight: 500,
  },
  cameraScheduleResource: {
    fontSize: 12,
    alignSelf: 'start',
    whiteSpace: 'nowrap',
  },
  groupIcon: {
    cursor: 'pointer',
  },
  mainHeader: {
    width: '100%',
    height: 92,
    alignItems: 'flex-start',
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mainHeaderTitle: {
    fontSize: 20,
    fontWeight: 500,
    color: '#1A1A1E',
    marginTop: 6,
  },
  mainHeaderBlue: {
    backgroundColor: '#0078FF',
    fontSize: 14,
  },
  mainHeaderMove: {
    backgroundColor: '#EEF4FF',
  },
  mainHeaderOrganize: {
    fontWeight: 500,
    color: '#0078FF',
    fontSize: 14,
    textTransform: 'uppercase',
    marginTop: 16,
    cursor: 'pointer',
  },
  mainHeaderGroupButtonRoot: {
    color: '#0078FF',
    backgroundColor: theme.palette.common.white,
    width: 115,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  mainHeaderGroupButtonLabel: {
    color: '#0078FF',
    justifyContent: 'left',
    textTransform: 'uppercase',
  },
  groupButtonActive: {
    backgroundColor: theme.palette.common.white
  },
  showSchedulesButtonLabel: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  moveCancelLabel: {
    color: '#007AFF',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  scheduleIcon: {
    marginRight: 8,
  },
  menuIcon: {
    marginRight: 8,
    minWidth: 24,
  },
  menuItem: {
    cursor: 'pointer',
  },
  groupButtonIcon: {
    marginRight: 6,
  },
  timezone: {
    color: '#AAAAAA',
    fontSize: 12,
  },
  deleteText: {
    color: '#BC0505',
  },
  leftColumnTextWidth: {
    maxWidth: 280,
    overflowX: 'auto',
  }
});