import { calendarConstants } from '../constants';
import { notifierActions } from './notifier.actions';
import { calendarService } from '../services/calendar.service';

export const calendarActions = {
  getResources,
  getCalendars,
  applySchedule,
  createSchedule,
  editSchedule,
  deleteSchedule,
  groupResources,
  unGroupResources,
  moveResources
};

function getResources() {
  return dispatch => {
    dispatch(request());

    calendarService.getResources()
      .then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };

  function request() {
    return { type: calendarConstants.RESOURCES_REQUEST }
  }

  function success(data) {
    return { type: calendarConstants.RESOURCES_SUCCESS, ...data  }
  }

  function failure(error) {
    return { type: calendarConstants.RESOURCES_FAILURE, error }
  }
}

function getCalendars() {
  return dispatch => {
    dispatch(request());

    calendarService.getCalendars()
      .then(
        data => {
          dispatch(success(data));
        },
        error => {
          dispatch(failure(error));
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };

  function request() {
    return { type: calendarConstants.CALENDARS_REQUEST }
  }

  function success(calendars) {
    return { type: calendarConstants.CALENDARS_SUCCESS, calendars }
  }

  function failure(error) {
    return { type: calendarConstants.CALENDARS_FAILURE, error }
  }
}

function groupResources(data) {
  return dispatch => {
    return calendarService.groupResources(data)
      .then(
        () => {
          dispatch(getResources());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };
}

function unGroupResources(data) {
  return dispatch => {
    return calendarService.unGroupResources(data)
      .then(
        () => {
          dispatch(getResources());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
              variant: 'error',
            },
          }));
        }
      );
  };
}

function moveResources(data) {
  return dispatch => {
    return calendarService.moveResources(data)
      .then(
        () => {
          dispatch(getResources());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };
}

function applySchedule(scheduleId, type = "camera", id) {
  return dispatch => {
    return calendarService.applySchedule(scheduleId, type, id)
      .then(
        () => {
          dispatch(getResources());
          dispatch(getCalendars());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };
}

function editSchedule(scheduleId, schedule) {
  return dispatch => {
    return calendarService.editSchedule(scheduleId, schedule)
      .then(
        () => {
          dispatch(getResources());
          dispatch(getCalendars());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };
}

function deleteSchedule(scheduleId) {
  return dispatch => {
    return calendarService.deleteSchedule(scheduleId)
      .then(
        () => {
          dispatch(getResources());
          dispatch(getCalendars());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };
}

function createSchedule(schedule) {
  return dispatch => {
    return calendarService.createSchedule(schedule)
      .then(
        () => {
          dispatch(getResources());
          dispatch(getCalendars());
        },
        error => {
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
                variant: 'error',
            },
        }));
        }
      );
  };
}