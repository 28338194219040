import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    color: "#979797",
  },
});

export default props => {
  const classes = useStyles(props);
  return <Checkbox {...props} classes={{ root: classes.root }} />;
};
