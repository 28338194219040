import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { userService } from '../services';
import { ACCOUNT_URL, userConstants } from '../constants';
import { connect } from 'react-redux';


class PrivateRoute extends React.Component {

  redirectOut() {
    window.location.href = ACCOUNT_URL;
  }

  checkToken() {
    const { auth, location} = this.props;

    if (auth.loggedIn) return;

    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (token) {
      return userService.handleTokenRetrieve(token).then((user) => {
        this.props.dispatch({ type: userConstants.LOGIN_SUCCESS, user });
      }).catch(e => this.redirectOut())
    } else {
      this.redirectOut();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    this.checkToken();

    return (
      <Route {...rest} render={props => (
        <Component {...props} />
      )} />
    )
  }
}

function mapState(state) {
  const { auth } = state;
  return { auth };
}

const PrivateRouteConnected = connect(mapState)(withRouter(PrivateRoute));

export { PrivateRouteConnected as PrivateRoute };
