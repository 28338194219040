import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { userService } from '../services';
import { connect } from 'react-redux';
import { userConstants } from '../constants';

class TokenAuth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
    };


    if (props.auth.loggedIn) return;

    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');

    if (token) {
      userService.handleTokenRetrieve(token).then((user) => {
        props.dispatch({ type: userConstants.LOGIN_SUCCESS, user });
        this.setState({ loggedIn: true });
      });
    }
  }

  render() {
    const { loggedIn } = this.state;

    if (loggedIn) {
      return <Redirect to="/schedule" />
    }

    return null;
  }
}

function mapState(state) {
  const { auth } = state;
  return { auth };
}

const TokenAuthConnected = connect(mapState)(withRouter(TokenAuth));

export { TokenAuthConnected as TokenAuth };
