import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const borderStyle = '1px solid #0031FF';

const useStyle = makeStyles(theme => ({
  slot: {
    height: 12,
    width: 24,
    display: 'flex',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 3,
  },
  quarter: {
    height: 12,
    width: 6,
    borderTop: borderStyle,
    borderBottom: borderStyle,
  },
  left: {
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    borderLeft: borderStyle,
  },
  right: {
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    borderRight: borderStyle,
  },
  filled: {
    backgroundColor: '#007AFF',
  },
  empty: {
    backgroundColor: '#FFF',
  },
  preFilled: {
    backgroundColor: '#99CAFF',
  },
}));


const Slot = ({ intervals, onClick, preFilledSlots }) => {
  const classes = useStyle();

  return (
    <div className={classes.slot} onClick={onClick}>
      <div className={`${classes.left} ${classes.quarter} ${intervals.charAt(0) === '1' ? classes.filled : classes.empty} ${preFilledSlots[0] ? classes.preFilled : ''}`} />
      <div className={`${classes.quarter} ${intervals.charAt(1) === '1' ? classes.filled : classes.empty} ${preFilledSlots[1] ? classes.preFilled : ''}`} />
      <div className={`${classes.quarter} ${intervals.charAt(2) === '1' ? classes.filled : classes.empty} ${preFilledSlots[2] ? classes.preFilled : ''}`} />
      <div className={`${classes.right} ${classes.quarter} ${intervals.charAt(3) === '1' ? classes.filled : classes.empty} ${preFilledSlots[3] ? classes.preFilled : ''}`} />
    </div>
  );

};

Slot.propTypes = {
  intervals: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  preFilledSlots: PropTypes.array,
};

Slot.defaultProps = {
  preFilledSlots: [false, false, false, false],
};

export default Slot;