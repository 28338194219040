import { authHeader } from '../helpers';
import { handleResponse } from './index';
import { calendarConstants } from '../constants/calendar.constants';
import RRule from 'rrule';
import moment from 'moment'
import { API_URL } from '../constants';

export const calendarService = {
  getCalendars,
  getResources,
  applySchedule,
  createSchedule,
  editSchedule,
  deleteSchedule,
  unGroupResources,
  groupResources,
  moveResources,
  getTimeSlots,
  daysIntervalToRruleByWeekday,
};

const repeatToFrequency = {
  [calendarConstants.repeat.DAILY]: RRule.DAILY,
  [calendarConstants.repeat.WEEKLY]: RRule.WEEKLY,
  [calendarConstants.repeat.MONTHLY]: RRule.MONTHLY,
  [calendarConstants.repeat.ANNUALLY]: RRule.YEARLY,
};

const daysToByWeekly = {
  0: RRule.SU,
  1: RRule.MO,
  2: RRule.TU,
  3: RRule.WE,
  4: RRule.TH,
  5: RRule.FR,
  6: RRule.SA,
};

function getCalendars() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${API_URL}/calendars/`, requestOptions).then(handleResponse);
}

function getTimeSlots(ruleIntervals) {
  const slots = [];
  let hours = 0;
  let minutes = 0;
  let start_time = null;

  const hourMinToTime = (hours, minutes ) => ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
  const intervals = ruleIntervals.split('');

  for (let key = 0; key < calendarConstants.SLOTS_COUNT; key++) {
    if (intervals[key] === '1' && !start_time) {
        start_time = hourMinToTime(hours, minutes);
    }

    if (intervals[key] === '0' && start_time) {
      slots.push({
        start_time,
        end_time: hourMinToTime(hours, minutes),
      });
      start_time = null;
    }


    minutes = (minutes + 15) % 60;
    hours = hours + (minutes ? 0 : 1);

    if(key === 95 && intervals[key] === '1') {
      slots.push({
        start_time,
        end_time: hourMinToTime(hours, minutes),
      });
    }
  }

  return slots;
}

function daysIntervalToRruleByWeekday(days) {
  return days.split('')
    .reduce((accumulator, key, index) => {
      if (key === '1') {
        accumulator.push(daysToByWeekly[index])
      }

      return accumulator;
    }, []);
}

function parseResources(resourcesData) {
  const cameras = [];
  const groups = {};
  const events = [];

  const groupWithChild = new Set([]);

  resourcesData.groups.forEach( group => {
    const gid = `g${group.id}`;

    groups[group.id] = {
      id: gid,
      name: group.name,
      type: 'group',
      groupOnly: true,
      cameras: {},
    };

    if (group.parent) {
      groups[group.id]['parentId'] = `g${group.parent}`;
      groupWithChild.add(`g${group.parent}`);
    }
  });

  resourcesData.cameras.forEach(camera => {
    let parentId = undefined;
    // currently camera have only one group
    camera.groups.forEach(groupId => {
      if (groupId in groups) {
        groups[groupId]['cameras'][camera.id] = camera.name;
        parentId = `g${groupId}`;
      }
    });

    cameras.push({
      id: `c${camera.id}`,
      name: camera.name,
      timezone: camera.timezone,
      type: 'camera',
      calendarName: camera.calendar ? camera.calendar.name : '',
      parentId: parentId,
    });


    if (camera.calendar && camera.calendar.is_active) {
      camera.calendar.calendar_rules.forEach(rule => {
        getTimeSlots(rule.intervals).forEach((slot, key) => {
          const event = {
            id: `${rule.id}_${key}`,
            start: `${rule.date || moment().format("YYYY-MM-DD")} ${slot.start_time}`,
            end: `${rule.date || moment().format("YYYY-MM-DD")} ${slot.end_time}`,
            resourceId: `c${camera.id}`,
            title: '',
            is_active: rule.is_active,
            calendarId: camera.calendar.id,
            resizable: false,
            rule,
          };

          if (rule.repeat !== calendarConstants.repeat.NO) {
            const rruleData = {
              freq: repeatToFrequency[rule.repeat],
            };

            if (rule.repeat === calendarConstants.repeat.WEEKLY) {
              rruleData.byweekday = daysIntervalToRruleByWeekday(rule.days)
            }

            event.rrule = (new RRule(rruleData)).toString();
          }
          events.push(event);
        });
      });
    }
  });

  // filter out groups without child or cameras
  const filteredGroups = Object.values(groups).filter(group => {
    return Object.keys(group.cameras).length || groupWithChild.has(group.id);
  });

  return { resources: [...filteredGroups, ...cameras], events }
}

async function getResources() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const resourcesData = await fetch(`${API_URL}/calendars/resources/`, requestOptions).then(handleResponse);

  return parseResources(resourcesData);
}

function applySchedule(scheduleId, type = "camera", id) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify({ id, type, schedule: scheduleId })
  };

  return fetch(`${API_URL}/calendars/apply/`, requestOptions).then(handleResponse);
}

function createSchedule(rule) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(rule)
  };

  return fetch(`${API_URL}/calendars/`, requestOptions).then(handleResponse);
}

function editSchedule(calendarId, rule) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(rule)
  };

  return fetch(`${API_URL}/calendars/${calendarId}/`, requestOptions).then(handleResponse);
}

function deleteSchedule(calendarId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(`${API_URL}/calendars/${calendarId}/`, requestOptions).then(handleResponse);
}

function groupResources(data) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${API_URL}/groupcameras/`, requestOptions).then(handleResponse);
}

function unGroupResources(data) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${API_URL}/ungroupcameras/`, requestOptions).then(handleResponse);
}

function moveResources(data) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: JSON.stringify(data)
  };

  return fetch(`${API_URL}/movecameras/`, requestOptions).then(handleResponse);
}
