import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { history } from '../helpers';
import { PrivateRoute } from '../components/PrivateRoute';
import { SchedulePage } from './SchedulePage';
import { LoginPage } from './LoginPage';
import { NotFoundPage } from './NotFoundPage';
import { MainAppBar } from '../components/MainAppBar';
import { makeStyles } from '@material-ui/core';
import Notifier from './Notifier';
import { TokenAuth } from '../components/TokenAuth';

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    ...theme.mixins.toolbar,
  },
}));

const App = (props) => {

  // const { auth } = props;
  const classes = useStyles();

  return (
    <Router history={history}>
      <MainAppBar />
      <TokenAuth />
      <div className={classes.drawerHeader} />
      <Notifier />
      <Switch>
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/schedule" component={SchedulePage} />
        <Route path="/" render={() => (
          <Redirect to="/schedule" />
          // auth.loggedIn ? (
          //   <Redirect to="/schedule" />
          // ) : (
          //   <Redirect to="/login" />
          // )
        )} />
        <Route path="" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

function mapState(state) {
  const { auth } = state;
  return { auth };
}

const actionCreators = {};

const connectedApp = connect(mapState, actionCreators)(App);
export { App };
export default connectedApp;