import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import { DatePicker } from '@material-ui/pickers';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';

import Modal from '../../../components/base/Modal';
import Button from '../../../components/base/Button';
import TextField from '../../../components/base/TextField';
import { calendarConstants } from '../../../constants';
import { calendarActions } from '../../../actions/calendar.actions';
import { notifierActions } from '../../../actions/notifier.actions';
import Slot from './Slot';
import { calendarService } from '../../../services/calendar.service';
import { replaceAt, timeConvert } from '../../../helpers';

function chunk(str, size) {
  return str.match(new RegExp('.{1,' + size + '}', 'g'));
}

const styles = theme => ({
  root: {
    width: 675,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1 / 3),
    top: theme.spacing(1 / 3),
    color: theme.palette.grey[500],
  },
  buttonCancel: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  scheduleIcon: {
    marginRight: 15,
    color: 'gray',
  },
  selectedTimeSlotsText: {
    color: '#1A1A1E',
    alignSelf: 'center',
  },
  repeatText: {
    color: '#1A1A1E',
    marginRight: 15,
    marginLeft: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  weekDay: {
    width: 32,
    height: 24,
    backgroundColor: '#FFF',
    color: '#1A1A1E',
    fontSize: 12,
    border: '1px solid #0031FF',
    borderRadius: 2,
    marginLeft: 2,
    marginRight: 2,
    textAlign: 'center',
    paddingTop: 3,
    cursor: 'pointer',
    userSelect: 'none',
  },
  weekDayActive: {
    backgroundColor: '#0078FF',
    color: '#FFF',
  },
  switchTitle: {
    fontSize: 16,
    marginLeft: 8,
  },
  inputRoot: {
    backgroundColor: '#F5F5F5',
    borderRadius: 3,
  },
  intervalHours: {
    fontSize: 9,
    lineHeight: '8px',
    marginTop: 4,
    width: 27,
    textAlign: 'center',
  },
  intervalHoursWrapper: {
    marginLeft: -12,
  },
  slotsWrapper: {
    marginLeft: 12,
  },
  ruleControl: {
    height: 36,
    width: 36,
    boxShadow: 'none',
  }
});

const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const calendarRuleEmpty = {
  date: null,
  intervals: '0'.repeat(calendarConstants.SLOTS_COUNT),
  repeat: calendarConstants.repeat.NO,
  days: '0000000',
  settings: [{
    setting: calendarConstants.settings.NOTIFICATION,
    on: true
  }, { setting: calendarConstants.settings.RECORDING, on: true }],
  is_active: true,
};

const defaultDayInterval = '111111111111111111111111000000000000000000000000000000000000000000000000000000000000000011111111';
const defaultDays = '0111110';

class ScheduleEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectTimeAnchorEl: null,
      slotKeyClicked: null,
      startTimeSelected: {},
      slotRuleClicked: null,
      values: {},
    };
  }


  initForm = scheduleData => {
    this.setState({
      values: {
        name: scheduleData.name || '',
        calendar_rules: scheduleData.calendar_rules ?
          [...scheduleData.calendar_rules] :
          [{
            ...calendarRuleEmpty,
            intervals: defaultDayInterval,
            days: defaultDays,
            repeat: calendarConstants.repeat.WEEKLY,
          }],
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.scheduleData !== this.props.scheduleData) {
      // init form
      this.initForm(this.props.scheduleData);
    }
  }

  validateRules = rules => {
    return !rules.some(
      rule => {
        const noDate = !([calendarConstants.repeat.DAILY, calendarConstants.repeat.WEEKLY].includes(rule.repeat)) && !rule.date;
        const noWeeklyDays = rule.repeat === calendarConstants.repeat.WEEKLY && rule.days === '0000000';
        return noDate || noWeeklyDays;
      }
    );
  };

  submitCalendar = (event) => {
    event.preventDefault();

    const { editSchedule, createSchedule, scheduleData, onClose } = this.props;
    const { values } = this.state;

    const preparedValues = {
      ...values,
      is_active: true,
    };

    scheduleData.id ? editSchedule(scheduleData.id, preparedValues) : createSchedule(preparedValues);
    this.setState({ values: {} });
    onClose();
  };

  handleChange = name => event => {
    this.setState({ values: { ...this.state.values, [name]: event.target.value } });
  };

  handleChangeRule = (ruleKey, name) => value => {
    const { values } = this.state;

    if (name === 'date' && typeof value !== 'string') {
      value = value.format('YYYY-MM-DD');
    }

    values.calendar_rules[ruleKey][name] = value;

    this.setState({
      startTimeSelected: {},
      values: {
        ...values,
      }
    });
  };

  handleChangeDay = (ruleKey, key) => () => {
    const { values } = this.state;
    values.calendar_rules[ruleKey]['days'] = replaceAt(values.calendar_rules[ruleKey]['days'], key, values.calendar_rules[ruleKey]['days'].charAt(key) === '1' ? '0' : '1');

    this.setState({
      startTimeSelected: {},
      values: {
        ...values,
      }
    });
  };

  renderSlotsText = intervals => {
    const slots = calendarService.getTimeSlots(intervals);
    if (!slots.length) {
      return 'Never';
    }

    return slots
      .map((slot, key) => <Box component="span" fontWeight={500}
                               key={key}>{timeConvert(slot.start_time)} to {timeConvert(slot.end_time)}</Box>)
      .reduce((prev, curr) => [prev, ' and ', curr]);
  };

  onSlotClick = (event, ruleKey, slotKey) => {
    const target = event.currentTarget;
    this.setState({ selectTimeAnchorEl: target, slotKeyClicked: slotKey, slotRuleClicked: ruleKey })
  };

  onSlotTimeSelect = key => () => {
    const { slotKeyClicked, slotRuleClicked, startTimeSelected, values } = this.state;
    const selectedInterval = slotKeyClicked + key;
    if (slotRuleClicked !== startTimeSelected.rule) {
      this.setState({
        startTimeSelected: {
          rule: slotRuleClicked,
          interval: selectedInterval,
        },
      });
    } else {
      const rule = values.calendar_rules[startTimeSelected.rule];

      if (selectedInterval > startTimeSelected.interval) {

        const selectedLength = selectedInterval - startTimeSelected.interval;

        values.calendar_rules[slotRuleClicked].intervals = replaceAt(rule.intervals, startTimeSelected.interval, '1'.repeat(selectedLength));
      } else {
        const selectedLength = values.calendar_rules[slotRuleClicked].intervals.length - startTimeSelected.interval;
        values.calendar_rules[slotRuleClicked].intervals = replaceAt(rule.intervals, 0, '1'.repeat(selectedInterval));
        values.calendar_rules[slotRuleClicked].intervals = replaceAt(rule.intervals, startTimeSelected.interval, '1'.repeat(selectedLength));
      }

      this.setState({
        startTimeSelected: {},
        values: {
          ...values,
        }
      });
    }
    this.onSlotTimeMenuClose(false);
  };

  onSlotTimeMenuClose = (cleanStartTimeSelected = true) => {
    this.setState(prevState => ({
      selectTimeAnchorEl: null,
      slotKeyClicked: null,
      startTimeSelected: cleanStartTimeSelected ? {} : prevState.startTimeSelected,
    }));
  };

  removeRule = key => {
    const { values } = this.state;
    if (values.calendar_rules.length <= 1) {
      return;
    }

    values.calendar_rules.splice(key, 1);
    this.setState({
      values: {
        ...values,
      }
    });
  };

  addRule = key => {
    const { values } = this.state;
    values.calendar_rules.splice(key + 1, 0, { ...calendarRuleEmpty });
    this.setState({
      values: {
        ...values,
      }
    });
  };

  deleteInterval = () => {
    const { slotKeyClicked, slotRuleClicked, values } = this.state;
    const currentIntervals = values.calendar_rules[slotRuleClicked].intervals.split('');
    currentIntervals[slotKeyClicked + 1] = '0';
    currentIntervals[slotKeyClicked + 2] = '0';

    if (currentIntervals[slotKeyClicked] === '1') {
      //go down till '0'
      for (let i = slotKeyClicked; i >= 0; i--) {
        if (currentIntervals[i] === '0') break;
        else currentIntervals[i] = '0';
      }
    }

    if (currentIntervals[slotKeyClicked + 3] === '1') {
      //go up till '0'
      for (let i = slotKeyClicked + 3; i < currentIntervals.length; i++) {
        if (currentIntervals[i] === '0') break;
        else currentIntervals[i] = '0';
      }
    }

    values.calendar_rules[slotRuleClicked].intervals = currentIntervals.join('');

    this.setState({
      values: {
        ...values,
      }
    });
    this.onSlotTimeMenuClose(false)
  };

  renderRule = (rule, ruleKey) => {
    const { classes } = this.props;
    const { startTimeSelected, slotKeyClicked } = this.state;

    const preFilledFrom = intervalKey => {
      const curIntervalItem = intervalKey * 4;
      const nextIntervalItem = (intervalKey + 1) * 4;
      const perilledSlots = [false, false, false, false];

      if (ruleKey !== startTimeSelected.rule || !slotKeyClicked) {
        return perilledSlots;
      }

      if (startTimeSelected.interval < slotKeyClicked) {
        if (startTimeSelected.interval >= nextIntervalItem || curIntervalItem > slotKeyClicked) {
          return perilledSlots;
        }

        if (startTimeSelected.interval < curIntervalItem) {
          return [true, true, true, true];
        }


        const startInterval = startTimeSelected.interval % 4;
        for (let i = 4; i >= startInterval; i--) {
          perilledSlots[i] = true;
        }
        return perilledSlots;
      } else {
        if (startTimeSelected.interval < nextIntervalItem && startTimeSelected.interval > curIntervalItem) {
          const startInterval = startTimeSelected.interval % 4;
          for (let i = 4; i >= startInterval; i--) {
            perilledSlots[i] = true;
          }
          return perilledSlots;
        }

        if (curIntervalItem > slotKeyClicked && curIntervalItem <= startTimeSelected.interval) {
          return perilledSlots;
        }

        return [true, true, true, true];
      }
    };

    const pickerViews = rule.repeat === calendarConstants.repeat.ANNUALLY ? ["date", "month"] :
      rule.repeat === calendarConstants.repeat.MONTHLY ? ["date"] :
        rule.repeat === calendarConstants.repeat.NO ? ["date", "month", "year"] : null;
    const pickerFormats = rule.repeat === calendarConstants.repeat.ANNUALLY ? "MMM DD" :
      rule.repeat === calendarConstants.repeat.MONTHLY ? "DD" : "MMM DD YYYY";

    return (
      <Box key={ruleKey}>
        <Box display="flex" mt={4} mb={2}>
          <Box className={classes.slotsWrapper}>
            <Box display="flex">
              {chunk(rule.intervals, 4).map((intervals, intervalKey) => (
                <Slot
                  preFilledSlots={preFilledFrom(intervalKey)}
                  onClick={e => this.onSlotClick(e, ruleKey, intervalKey * 4)}
                  key={intervalKey}
                  intervals={intervals}
                />
              ))}
            </Box>
            <Box display="flex" className={classes.intervalHoursWrapper}>
              {Array(25).fill().map((_, key) => (
                <Box className={classes.intervalHours} key={key}>
                  <Box>{(key + 11) % 12 + 1}</Box>
                  <Box>{key > 11 && key !== 24 ? 'pm' : 'am'}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="baseline">
          <ScheduleIcon className={classes.scheduleIcon} />
          <Box className={classes.selectedTimeSlotsText}>
            {this.renderSlotsText(rule.intervals)}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" mb={1}>
          <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              <Box className={classes.repeatText}>Repeat</Box>
              <Select
                name="repeat"
                value={rule.repeat}
                onChange={e => this.handleChangeRule(ruleKey, 'repeat')(e.target.value)}
                disableUnderline
              >
                <MenuItem value={calendarConstants.repeat.NO}>Do not repeat</MenuItem>
                <MenuItem value={calendarConstants.repeat.DAILY}>Daily</MenuItem>
                <MenuItem value={calendarConstants.repeat.WEEKLY}>Weekly on Days</MenuItem>
                <MenuItem value={calendarConstants.repeat.MONTHLY}>Monthly on a Day</MenuItem>
                <MenuItem value={calendarConstants.repeat.ANNUALLY}>Annually on a Day</MenuItem>
              </Select>
              <Box ml={2} display="flex" alignItems="center">
                {!!pickerViews &&
                <DatePicker
                  clearable
                  placeholder={rule.repeat === calendarConstants.repeat.MONTHLY ? 'Select Day' : 'Select Date'}
                  views={pickerViews}
                  format={pickerFormats}
                  value={rule.date}
                  onChange={this.handleChangeRule(ruleKey, 'date')}
                  InputProps={{ disableUnderline: true }}
                />}
                {rule.repeat === calendarConstants.repeat.WEEKLY &&
                weekDays.map((day, key) =>
                  <div key={key}
                       className={`${classes.weekDay} ${rule.days.charAt(key) === '1' ? classes.weekDayActive : ''}`}
                       onClick={this.handleChangeDay(ruleKey, key)}>{day}</div>
                )}
              </Box>
            </Box>

            <Box alignSelf="auto" justifyContent="space-between" width={90} display="flex">
              <Fab color="primary" aria-label="remove" className={classes.ruleControl}
                   onClick={() => this.removeRule(ruleKey)}>
                <RemoveIcon />
              </Fab>
              <Fab color="primary" aria-label="add" className={classes.ruleControl}
                   onClick={() => this.addRule(ruleKey)}>
                <AddIcon />
              </Fab>
            </Box>

          </Box>
        </Box>
        <Divider variant="fullWidth" />
      </Box>
    );
  };

  render() {
    const { onClose, open, classes } = this.props;
    const { values, selectTimeAnchorEl, slotKeyClicked, slotRuleClicked } = this.state;

    const selectedHour = (slotKeyClicked / 4 + 11) % 12 + 1;
    const nextSelectedHour = (slotKeyClicked / 4 + 12) % 12 + 1;
    const selectedHourPeriod = slotKeyClicked / 4 > 11 ? 'pm' : 'am';
    const nextHourPeriod = slotKeyClicked / 4 <= 10 || slotKeyClicked / 4 === 23 ? 'am' : 'pm';

    const currentIntervals = (values.calendar_rules || [])[slotRuleClicked] ? values.calendar_rules[slotRuleClicked].intervals : '';
    const isThereIntervalUnderClickedSlot = +(currentIntervals.charAt(slotKeyClicked) +
      currentIntervals.charAt(slotKeyClicked + 1) +
      currentIntervals.charAt(slotKeyClicked + 2) +
      currentIntervals.charAt(slotKeyClicked + 3));

    const submitDisabled = !values.name || !this.validateRules(values.calendar_rules);

    return (
      <Modal open={open}>
        <form className={classes.root}>
          <Box id="modal-title" display="flex" className={classes.titleWrapper} alignItems="center">
            <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="column">
            <Box mt={1} width="90%">
              <TextField
                value={values.name}
                onChange={this.handleChange('name')}
                margin="none"
                hiddenLabel
                variant="filled"
                placeholder="Schedule name"
                InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
                fullWidth
                required
              />
            </Box>

            {(values.calendar_rules || []).map((rule, key) => this.renderRule(rule, key))}
          </Box>
          <Box display="flex" mt={4}>
            <div className={classes.buttonCancel}>
              <Button color="primary" size="small" onClick={onClose}>Cancel</Button>
            </div>
            <Button variant="contained" color="primary" size="small" disabled={submitDisabled}
                    onClick={this.submitCalendar}
                    type="submit">Save</Button>
          </Box>
        </form>

        <Menu
          id="pick-interval"
          anchorEl={selectTimeAnchorEl}
          keepMounted
          open={Boolean(selectTimeAnchorEl)}
          onClose={this.onSlotTimeMenuClose}
          transitionDuration={0}
        >
          {!!isThereIntervalUnderClickedSlot && <MenuItem onClick={this.deleteInterval}>Delete Interval</MenuItem>}
          <MenuItem onClick={this.onSlotTimeSelect(0)}>{selectedHour}:00 {selectedHourPeriod}</MenuItem>
          <MenuItem onClick={this.onSlotTimeSelect(1)}>{selectedHour}:15 {selectedHourPeriod}</MenuItem>
          <MenuItem onClick={this.onSlotTimeSelect(2)}>{selectedHour}:30 {selectedHourPeriod}</MenuItem>
          <MenuItem onClick={this.onSlotTimeSelect(3)}>{selectedHour}:45 {selectedHourPeriod}</MenuItem>
          <MenuItem onClick={this.onSlotTimeSelect(4)}>{nextSelectedHour}:00 {nextHourPeriod}</MenuItem>
        </Menu>
      </Modal>
    );
  }
}

ScheduleEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  scheduleData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

function mapState(state) {
  return {};
}

const actionCreators = {
  createSchedule: calendarActions.createSchedule,
  editSchedule: calendarActions.editSchedule,
  enqueueSnackbar: notifierActions.enqueueSnackbar,
};

const StyledScheduleEditModal = connect(mapState, actionCreators)(withStyles(styles)(ScheduleEditModal));
export { StyledScheduleEditModal as ScheduleEditModal };