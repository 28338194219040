import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Copyright } from './Copyright';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 'auto',
  },
}));


export const Footer = () => {
  const classes = useStyles();
  return (<footer className={classes.root}><Copyright/></footer>);
};
