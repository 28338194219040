import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import Modal from '../../../components/base/Modal';
import Button from '../../../components/base/Button';
import { ScheduleEditModal } from './ScheduleEditModal';
import { connect } from 'react-redux';
import cameraWhiteIcon from '../icons/camera-white.svg'
import { calendarActions } from '../../../actions/calendar.actions';
import Checkbox from '../../../components/base/Checkbox';


const styles = theme => ({
  root: {
    width: 660,
  },
  titleWrapper: {
    alignItems: 'baseline'
  },
  title: {
    fontSize: 20,
    textAlign: 'left',
    marginRight: 20,
    marginBottom: 30,
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1 / 3),
    top: theme.spacing(1 / 3),
    color: theme.palette.grey[500],
  },
  buttonCancel: {
    marginLeft: 'auto',
    marginRight: 10,
  },
  scheduleName: {
    minWidth: 300,
    flex: 1,
  },
  scheduleIcon: {
    marginRight: 10,
    color: 'gray',
  },
  cameraIcon: {
    color: theme.palette.common.white,
    backgroundColor: '#979797',
  },
  cameraIconZero: {
    backgroundColor: '#D5D5D5',
  }
});

class SchedulesEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scheduleEditOpened: false,
      selectedSchedule: {},
      confirmDeleteSchedule: null,
      confirmDeleteChecked: false,
    };
  }

  onScheduleEditOpen = (selectedSchedule = {}) => {
    this.setState({ selectedSchedule, scheduleEditOpened: true });
  };

  onScheduleEditClose = () => {
    this.setState({ selectedSchedule: {}, scheduleEditOpened: false });
  };

  onScheduleCloneOpen = (selectedSchedule = {}) => {
    this.setState(
      {
        selectedSchedule: {
          name: selectedSchedule.name + ' copy',
          calendar_rules: selectedSchedule.calendar_rules.map(rule => {
            delete rule.id;
            rule.settings = rule.settings.map(setting => {
              delete setting.id;
              return setting;
            });
            return rule;
          }),
          is_active: true,
        },
        scheduleEditOpened: true
      }
    );
  };

  handleConfirmDeleteClose = () => {
    this.setState({ confirmDeleteSchedule: null, confirmDeleteChecked: false });
  };

  handleConfirmDeleteOpen = schedule => {
    this.setState({ confirmDeleteSchedule: schedule, confirmDeleteChecked: false });
  };

  onScheduleDelete = () => {
    const { confirmDeleteSchedule } = this.state;
    const { deleteSchedule } = this.props;
    deleteSchedule(confirmDeleteSchedule.id);
    this.handleConfirmDeleteClose();
  };


  render() {
    const { classes, calendars, onClose, open } = this.props;
    const { selectedSchedule, scheduleEditOpened, confirmDeleteSchedule, confirmDeleteChecked } = this.state;


    const selectedScheduleCameraDeleteCount = (confirmDeleteSchedule || {}).cameras_count;
    const selectedScheduleCameraDeleteName = (confirmDeleteSchedule || {}).name;

    return (
      <Modal open={open}>
        <Box className={classes.root}>
          <Box id="modal-title" display="flex" className={classes.titleWrapper}>
            <div className={classes.title}>Schedules</div>
            <Button color="primary" onClick={this.onScheduleEditOpen}>Create new schedule</Button>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="column" pb={2}>
            {calendars.map((schedule, key) => (
              <Box display="flex" justifyContent="space-between" key={key}>
                <Box mr={4}>
                  <Chip size="small" icon={<img alt="" src={cameraWhiteIcon} />}
                        className={`${classes.cameraIcon} ${schedule.cameras_count ? classes.cameraIcon: classes.cameraIconZero}`}
                        label={schedule.cameras_count} />
                </Box>
                <div className={classes.scheduleName}>{schedule.name}</div>
                <Button color="primary" size="small" onClick={() => this.onScheduleCloneOpen(schedule)}>Clone</Button>
                <Button color="primary" size="small" onClick={() => this.onScheduleEditOpen(schedule)}>Edit</Button>
                <Button color="secondary" size="small"
                        onClick={() => this.handleConfirmDeleteOpen(schedule)}>Delete</Button>
              </Box>
            ))}
          </Box>
        </Box>
        <ScheduleEditModal scheduleData={selectedSchedule} open={scheduleEditOpened}
                           onClose={this.onScheduleEditClose} />
        <Dialog
          open={!!confirmDeleteSchedule}
          onClose={this.handleConfirmDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {selectedScheduleCameraDeleteCount ? `Warning, "${selectedScheduleCameraDeleteName}" schedule was applied to a number of cameras` : "Schedule Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" color="textPrimary">
              <Checkbox
                checked={confirmDeleteChecked}
                onChange={() => this.setState({ confirmDeleteChecked: !confirmDeleteChecked })}
                color="primary"
              />
              I understand the deleting the schedule will clear schedules
              for <b>{selectedScheduleCameraDeleteCount} cameras.</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleConfirmDeleteClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onScheduleDelete} color="primary" autoFocus disabled={!confirmDeleteChecked}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Modal>
    );
  }
}

SchedulesEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

function mapState(state) {
  const { calendars } = state.calendar;
  return { calendars };
}

const actionCreators = {
  deleteSchedule: calendarActions.deleteSchedule,
};


const StyledSchedulesEditModal = connect(mapState, actionCreators)(withStyles(styles)(SchedulesEditModal));
export { StyledSchedulesEditModal as SchedulesEditModal };